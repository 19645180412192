<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: calc(100vh - 34px);">
      <el-form
        label-position="top"
        :model="campaign"
        ref="campaignForm"
        label-width="100px"
        :rules="rules"
        class="form-container"
      >
        <el-row type="flex" style="padding-top: 34px">
          <el-col :span="12" :offset="6">
            <page-header
              :title="
                __(':campaign_type Campaign', {
                  campaign: campaign.campaign_type
                })
              "
              :contentId="id"
            ></page-header>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Campaign Name')"
              prop="campaign_name"
            >
              <el-input
                :value="campaign.campaign_name"
                @input="$set(campaign, 'campaign_name', $event)"
                v-loading="isChecking"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Task')"
              prop="task_id"
            >
              <el-select
                :value="campaign.task_id"
                @input="$set(campaign, 'task_id', $event)"
                default-first-option
                :placeholder="__('Select a Task')"
                filterable
                v-loading="taskLoading"
                @change="getPhoneNumbersFromRemote"
              >
                <el-option-group
                  v-for="(tasks, task_type) in filteredTasks"
                  :key="task_type"
                  :label="
                    __(task_type.charAt(0).toUpperCase() + task_type.slice(1))
                  "
                >
                  <el-option
                    v-for="task in tasks"
                    :key="task.task_id"
                    :label="task.task_name"
                    :value="task.task_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Passed Parameters')"
              prop="campaign_passed_param"
            >
              <el-select
                :value="campaign.campaign_passed_params.data"
                @input="$set(campaign.campaign_passed_params, 'data', $event)"
                multiple
                default-first-option
                :placeholder="__('Select')"
              >
                <el-option
                  v-for="passed_param in passedParameterVariables"
                  :key="passed_param.variable_id"
                  :label="passed_param.variable_name"
                  :value="passed_param.variable_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          v-if="
            taskType !== 'chatbot' ||
              (campaign.campaign_channel &&
                campaign.campaign_channel !== 'chatbot')
          "
        >
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Caller ID')"
                prop="campaign_caller_id"
              >
                <el-select
                  :value="campaign.campaign_caller_id"
                  @input="$set(campaign, 'campaign_caller_id', $event)"
                  :placeholder="__('Select a Caller Id')"
                  filterable
                  default-first-option
                  v-loading="phoneNumbersLoading"
                >
                  <el-option
                    v-for="phone_number in phone_numbers"
                    :key="phone_number.phone_number"
                    :label="phone_number.phone_number"
                    :value="phone_number.phone_number"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('From Number')"
                prop="from_number"
              >
                <el-input
                  :value="campaign.from_number"
                  @input="$set(campaign, 'from_number', $event)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Display Name')"
                prop="display_name"
              >
                <el-input
                  :value="campaign.display_name"
                  @input="$set(campaign, 'display_name', $event)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div
          v-if="
            taskType === 'voice' ||
              (campaign.campaign_channel &&
                campaign.campaign_channel === 'voice')
          "
        >
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Simultaneous Virtual Agents(threads)')"
                prop="threads"
              >
                <div style="display: flex; align-items: center">
                  <el-slider
                    :value="campaign.threads"
                    @input="$set(campaign, 'threads', $event)"
                    style="width: 400px; margin-right: 20px"
                    :min="0"
                    :max="maxThreads"
                  ></el-slider>
                  <el-input-number
                    :value="campaign.threads"
                    @input="$set(campaign, 'threads', $event)"
                    :min="0"
                    :max="maxThreads"
                    size="small"
                  ></el-input-number>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Dial Timeout(seconds)')"
                prop="dial_timeout"
              >
                <el-input-number
                  :value="campaign.dial_timeout"
                  @input="$set(campaign, 'dial_timeout', $event)"
                  :min="5"
                  :max="60"
                  :step="5"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Number of redials')"
                prop="redial"
              >
                <el-input-number
                  :value="campaign.retry_count"
                  @input="$set(campaign, 'retry_count', $event)"
                  :min="0"
                  :max="5"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="campaign.retry_count !== 0">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Redial Busy Interval')"
                prop="redial"
              >
                <el-select
                  :value="campaign.retry_busy_delay"
                  @input="$set(campaign, 'retry_busy_delay', $event)"
                  :placeholder="__('Select number of redials')"
                  default-first-option
                >
                  <el-option
                    v-for="redial in getRedials"
                    :key="redial"
                    :label="redial"
                    :value="redial"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="campaign.retry_count !== 0">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Redial Error Interval')"
                prop="redial"
              >
                <el-select
                  :value="campaign.retry_error_delay"
                  @input="$set(campaign, 'retry_error_delay', $event)"
                  :placeholder="__('Select number of redials')"
                  default-first-option
                >
                  <el-option
                    v-for="redial in getRedials"
                    :key="redial"
                    :label="redial"
                    :value="redial"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row type="flex" v-if="campaign.campaign_type === 'List-Based'">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top campaign-schedules"
              :label="__('Campaign Schedule')"
              prop="campaign_schedules"
            >
              <div style="display: flex;padding: 10px 0">
                <div
                  style="width: 7%;display: flex;justify-content: flex-start"
                >
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="runOnAllDays"
                    @change="handleCheckAllChange"
                  />
                </div>
                <div
                  style="width: 20%;display: flex;justify-content: flex-start"
                >
                  {{ __("Day") }}
                </div>
                <div style="width: 20%;display: flex;justify-content: center">
                  {{ __("Start Time") }}
                </div>
                <div style="width: 10%"></div>
                <div style="width: 20%;display: flex;justify-content: center">
                  {{ __("End Time") }}
                </div>
                <div style="width: 20%;display: flex;justify-content: center">
                  {{ __("After Hour Action") }}
                </div>
              </div>
              <div
                v-for="schedule in campaign.campaign_schedules.data"
                v-bind:key="schedule.schedule_day"
                style="display: flex; padding: 10px 0"
              >
                <div
                  style="width: 7%;display: flex;justify-content: flex-start"
                >
                  <el-checkbox
                    :value="!schedule.skip_day"
                    @input="setSkipValue($event)(schedule)"
                  ></el-checkbox>
                </div>
                <div style="width: 20%">{{ __(schedule.schedule_day) }}</div>
                <el-time-select
                  :disabled="schedule.skip_day"
                  v-model="schedule.start_time"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55',
                    maxTime: schedule.end_time
                  }"
                  style="width: 20%"
                >
                </el-time-select>
                <div style="width: 10%; display:flex;justify-content: center">
                  {{ __("To") }}
                </div>
                <el-time-select
                  :disabled="schedule.skip_day"
                  arrow-control
                  v-model="schedule.end_time"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55',
                    minTime: schedule.start_time
                  }"
                  style="width: 20%"
                >
                </el-time-select>
                <div style="width: 20%;display: flex;justify-content: center">
                  <el-select
                    :disabled="schedule.skip_day"
                    v-model="schedule.after_hour_action"
                    default-first-option
                    style="width: 80%"
                  >
                    <el-option
                      v-for="item in ['Pause', 'Stop']"
                      :key="item"
                      :label="__(item)"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item>
              <el-button
                class="submitBtn"
                @click="submitForm()"
                :loading="isSubmitting"
                :disabled="!canWrite()"
                >{{ __("Save Changes") }}
              </el-button>
              <el-button class="cancelBtn" @click="$emit('cancel')"
                >{{ __("Cancel") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { validateCampaign } from "@/api/campaigns";
import { TASK_TYPES } from "@/constants/nodes";

export default {
  name: "createOrEditForm",
  components: { PageHeader },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: -1
    },
    maxThreads: {
      type: Number,
      required: true
    }
  },
  data() {
    let validateCampaignName = async (rule, value, callback) => {
      try {
        if (!(this.id !== -1 && value === this.originalName)) {
          this.isChecking = true;
          const res = await validateCampaign(
            this.campaign.campaign_type,
            value
          );
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Campaign already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    return {
      rules: {
        campaign_name: [
          {
            required: true,
            message: __("Please input a Campaign name")
          },
          {
            max: 300,
            message: __("Length should be no more than 300 characters"),
            trigger: "blur"
          },
          { validator: validateCampaignName, trigger: "blur" }
        ],
        task_id: [
          {
            required: true,
            message: __("Please select a task")
          }
        ],
        campaign_caller_id: [
          {
            required: true,
            message: __("Please select a Caller Id")
          }
        ]
      },
      taskType: "",
      isSubmitting: false,
      runOnAllDays: true,
      originalName: "",
      isChecking: false
    };
  },
  async created() {
    this.originalName = this.campaign.campaign_name;
  },
  methods: {
    ...mapActions("campaigns", {
      createCampaign: "createCampaign",
      updateCampaign: "updateCampaign"
    }),
    ...mapActions("phonenumbers", {
      getPhoneNumbers: "getCampaignNumbers"
    }),
    setRunOnAllDaysFlag() {
      this.runOnAllDays = _.every(
        this.campaign.campaign_schedules.data,
        schedule => !schedule.skip_day
      );
    },

    setSkipValue(val) {
      return schedule => {
        schedule.skip_day = !val;
        this.setRunOnAllDaysFlag();
      };
    },
    handleCheckAllChange(val) {
      if (this.isIndeterminate) {
        val = true;
      }
      const schedules = this.campaign.campaign_schedules.data;
      _.map(schedules, schedule => (schedule.skip_day = !val));
      this.runOnAllDays = val;
    },
    submitForm() {
      this.$set(this.campaign, "task_type", "");
      if (this.taskType) {
        this.$set(this.campaign, "task_type", this.taskType);
      }
      if (this.taskType === TASK_TYPES.CHATBOT) {
        if (this.campaign.campaign_passed_params.data.length === 0) {
          this.$message({
            type: "error",

            // eslint-disable-next-line
            message: __("Please select at least one parameter for Chatbot Campaign")
          });
          return;
        }
      }

      this.$refs.campaignForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createCampaign : this.updateCampaign;
          let campaign = _.cloneDeep(this.campaign);
          process({ campaign })
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Campaign added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Campaign updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.$emit("cancel");
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          console.log("error submit");
          return false;
        }
      });
    },
    getPhoneNumbersFromRemote(task_id, channel = null) {
      if (!channel) {
        this.taskType = _.find(_.flatMap(this.filteredTasks), {
          task_id
        }).task_type;
      } else {
        this.taskType = channel;
      }
      if (this.taskType === TASK_TYPES.CHATBOT) {
        this.rules.campaign_caller_id.required = false;
        return;
      }
      this.getPhoneNumbers({
        fetch_all: 1,
        for_campaign_cli: 1,
        ac_id: this.selectedAccountId,
        channel: this.taskType
      }).then(() => {
        console.log("channel" + channel);
        if (!channel) {
          this.$set(this.campaign, "campaign_caller_id", "");
        }
        let firstNumberMapped = _.find(this.phone_numbers, {
          task_id: this.campaign.task_id
        });
        if (firstNumberMapped && this.id === -1) {
          this.$set(
            this.campaign,
            "campaign_caller_id",
            firstNumberMapped.phone_number
          );
        } else if (!firstNumberMapped) {
          this.$message({
            type: "error",

            // eslint-disable-next-line
            message: __("The selected Task does not have any phone numbers mapped.")+ "\n" + __("Campaign will not work as expected")
          });
        }
      });
    }
  },
  computed: {
    ...mapState("tasks", {
      // groupedTasks: state => state.tasks,
      taskLoading: state => state.loading
    }),

    ...mapGetters("tasks", {
      groupedTasks: "groupedTasksByType"
    }),

    ...mapState("phonenumbers", {
      phone_numbers: state => state.phoneNumbers,
      phoneNumbersLoading: state => state.loading
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables"
    }),
    isIndeterminate() {
      const schedules = this.campaign.campaign_schedules.data;
      return (
        _.some(schedules, schedule => !schedule.skip_day) &&
        _.some(schedules, schedule => !!schedule.skip_day)
      );
    },
    getRedials() {
      return [5, 15, 30, 60];
    },

    filteredTasks() {
      return _.pickBy(this.groupedTasks, function(value, key) {
        return key !== "workflow";
      });
    }
  },
  mounted() {
    if (this.campaign.campaign_id) {
      this.getPhoneNumbersFromRemote(
        this.task_id,
        this.campaign.campaign_channel
      );

      if (this.campaign.campaign_type === "List-Based") {
        this.setRunOnAllDaysFlag();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

.submitBtn.is-disabled {
  color: #ffffff;
  //background-color: mix(#ffffff, $content-theme-color, 50%);
  border-color: $content-theme-outline-color !important;
}

::v-deep .el-input-number {
  .el-input-number__decrease:hover {
    color: $content-theme-hover-color;
  }

  .el-input-number__increase:hover {
    color: $content-theme-hover-color;
  }
}

.campaign-schedules {
  ::v-deep .el-input.is-disabled {
    .el-input__inner {
      border-color: #a0a8b5;
      background: #f5f5f5;
    }
  }
}
</style>
